import React from 'react'

const ZweihanderFooter = ({ zoneContent, sectionThemeObj }) => {
    const sectionContentObj = JSON.parse(zoneContent[0]?.ContentDTO?.ContentObject)
    return (
        <div id='footer' className='section footer-section'>
            <div className='footer-content'>
                <div className='privacy'>
                    <img src={sectionContentObj?.leftDecorLine.url} className='border-line' />
                    {sectionContentObj?.lists.map((data, i) => (
                        <React.Fragment key={`privacy${i}`}>
                            <a href={data?.href} style={{ color: `${sectionThemeObj?.footerLinks}` }}>{data?.name.toUpperCase()}</a>
                            {sectionContentObj?.lists.length !== (i + 1) && <span className='dot'></span>}
                        </React.Fragment>
                    ))
                    }
                    <img src={sectionContentObj?.rightDecorLine.url} className='border-line' />
                </div>
                <div className='contact-details' style={{ color: `${sectionThemeObj?.email}` }} >{`Contact: ${sectionContentObj?.contactDetails}`}</div>
                <div className='footer-text' style={{ color: `${sectionThemeObj?.footerLinks}` }}>{sectionContentObj?.footerText}</div>
            </div>
        </div>
    )
}

export default ZweihanderFooter