import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Auth0Provider } from "@auth0/auth0-react";
import ReactFullpage from '@fullpage/react-fullpage';
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";
import Layout from "@/components/layout";
import HeaderOne from "@/components/header-one";
import GlobalComponent from "@/components/global-component";
import Footer from "@/components/footer";
import 'react-toastify/dist/ReactToastify.css';

const GlobalPage = ({ pageContext: { applicationScreensData } }) => {
    const applicationDetails = { applicationScreen: applicationScreensData };
    const shopifyProductDetails = applicationScreensData?.shopifyList;
    const [zones, setZones] = useState([]);
    const [fullPageData, setFullPageData] = useState([]);
    const [screenName, setScreenName] = useState({});
    const [menuData, setMenuData] = useState([]);
    const [anchor, setAnchor] = useState([]);
    const [reforgedHeader, setReforgedHeader] = useState([]);
    const [activeSection, setActiveSection] = useState("home");
    const [activeHomeSection, setActiveHomeSection] = useState("");
    const [commonDetail, setCommonDetail] = useState();
    const [footerDetail, setFooterDetail] = useState([]);
    const [screens, setScreens] = useState([]);
    const [screenTheme, setScreenTheme] = useState({});
    const [isOpen, setIsOpen] = useState(false);
    const [isGameFeatureModalOpen, setIsGameFeatureModalOpen] = useState(false);
    const [isExploreeModalOpen, setIsExploreeModalOpen] = useState(false);
    const [isGalleryModalOpen, setIsGalleryModalOpen] = useState(false);
    const [isReforged, setIsReforged] = useState();
    const [webLogo, setwebLogo] = useState("https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/site/media/wogd_logo_thumbnail/20240604105325829_u6ctieawh8be4cg4f1xy.jpg");
    const location = (typeof window !== 'undefined') && window.location;
    const [menuContentObj, setMenuContentObj] = useState() 
    let myArray = []
    const allScreenTheme = applicationDetails?.applicationScreen?.screenTheme;
    const screenMapping = applicationDetails?.applicationScreen?.screenMapping
    const isLongWebAddress = screenMapping?.find(item =>
        location?.origin === item?.LongWebAddress ||
        location?.origin?.replace("//www.", "//") === item?.LongWebAddress ||
        location?.hostname === item?.LongWebAddress ||
        "www." + location?.hostname === item?.LongWebAddress
    )

    useEffect(() => {
        setMenuData(applicationDetails?.applicationScreen?.menuData);
        const screenMenu = applicationDetails?.applicationScreen?.reforgedMenu?.filter(menu => menu?.ScreenDTO?.Path?.toLowerCase() === location?.pathname?.toLowerCase())
        if (isLongWebAddress) {
            setReforgedHeader(applicationDetails?.applicationScreen?.reforgedMenu?.filter(item => item?.ScreenDTO?.Name === isLongWebAddress?.ScreenDTO?.Name))
            setMenuContentObj(applicationDetails?.applicationScreen?.reforgedMenu?.length && JSON.parse(applicationDetails?.applicationScreen?.reforgedMenu?.filter(menu => menu?.ScreenDTO?.Name?.toLowerCase() === isLongWebAddress?.ScreenDTO?.Name?.toLowerCase())?.[0]?.MenuDTO?.ContentObject || null))
        } else {
            // setReforgedHeader(screenMenu?.length ? screenMenu : applicationDetails?.applicationScreen?.reforgedMenu)
            setReforgedHeader(screenMenu?.length ? screenMenu : [])
            setMenuContentObj(applicationDetails?.applicationScreen?.reforgedMenu?.length && JSON.parse(applicationDetails?.applicationScreen?.reforgedMenu?.filter(menu => menu?.ScreenDTO?.Path?.toLowerCase() === location?.pathname?.toLowerCase())?.[0]?.MenuDTO?.ContentObject || applicationDetails?.applicationScreen?.reforgedMenu?.[0]?.MenuDTO?.ContentObject))
        }
        let screen = applicationDetails?.applicationScreen?.applicationScreen?.map(
            value => value.ScreenDTO
        );
        setScreens(screen);
        const appDetails = applicationDetails?.applicationScreen?.applicationContent?.map(value => value?.ContentDTO);
        const footerData = appDetails?.filter(item => item?.Name?.toLowerCase()?.includes('footer'))
        setCommonDetail(appDetails);
        // let footer = applicationDetails.applicationScreen.zoneContent.filter(
        //   value => value.Zone.Name.toLowerCase() === "Footer".toLowerCase()
        // );
        setFooterDetail(footerData);
        const path = window.location.pathname !== "/" && window.location.pathname.endsWith("/") ?
            window.location.pathname.slice(0, -1) :
            window.location.pathname;

        const targetPath = isLongWebAddress?.ScreenDTO?.Path?.toLowerCase() || path?.toLowerCase();

        const filteredZones = applicationDetails?.applicationScreen?.screenContent?.filter(value =>
            value?.ScreenDTO?.Path?.toLowerCase() === targetPath
        );

        setwebLogo((location.origin === "https://www.zweihanderreforged.com" || location.origin === "https://zweihanderreforged.com") ? "https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/site/media/zweihanderlogo/20240604105327853_vhh4zpd3x8lziojkqldh.jpg" : webLogo)
        setScreenName(filteredZones[0]?.ScreenDTO);
        let zone = [];
        let reforgedData = []
        let connectedTheme;
        let screenContent = []
        filteredZones.forEach(value => {
            zone.push(value);
            if (value?.ScreenDTO?.ScreenTypeId === 2) reforgedData.push(value)
        });

        allScreenTheme?.forEach((theme) => {
            if (filteredZones.some(value => value?.ScreenId === theme?.ScreenId)) connectedTheme = theme
        })
        const screenThemeObj = connectedTheme?.ThemeDTO?.ContentObject && JSON.parse(connectedTheme?.ThemeDTO?.ContentObject)
        setScreenTheme(screenThemeObj)
        setIsReforged(zone.some(zone => zone?.ScreenDTO?.ScreenTypeId === 2))
        zone.some((value) => {
            const content = value?.ContentDTO?.ContentObject && JSON.parse(value?.ContentDTO?.ContentObject);
            if (content.hasOwnProperty("apiURL")) {
                screenContent.push(value)
                return true;
            }
            screenContent.push(value)
            return false;
        })
        setZones(screenContent);
        setFullPageData(reforgedData)

    }, []);

    useEffect(() => {
        if (menuContentObj) {
            const newAnchors = getAnchors(menuContentObj?.lists)
            setAnchor(newAnchors)
        }
    }, [menuContentObj])

    const getAnchors = (item) => {
        item?.forEach((menu) => {
            if (!menu.subMenu.length && menu.path) {
                myArray.push(menu?.name.split(" ").join('')?.toLowerCase())
            }
            else {
                getAnchors(menu.subMenu)
            }
        })
        return myArray
    }

    const onLeave = (origin, destination, direction) => {
        setIsGameFeatureModalOpen(false)
        setIsGalleryModalOpen(false)
        setIsExploreeModalOpen(false)
        if (destination?.isLast) {
            setActiveHomeSection('home')
        } else {
            setActiveSection(destination.anchor)
            setActiveHomeSection("")
        }
    }

    const copyLink = () => {
        navigator.clipboard.writeText(window.location.toString());
        toast('Copied successfully!')
    }

    const onRedirectCallback = appState => {
        window.location.href =
            process.env.GATSBY_AUTH0_LOGIN_REDIRECT + appState?.returnTo ||
            window?.location?.pathname?.toLowerCase();
    };

    const renderFullpage = () => (
        <ReactFullpage
            licenseKey={process.env.GATSBY_FULLPAGE_LICENSE_KEY}
            anchors={[...anchor, 'footer-section']}
            // lockAnchors
            onLeave={onLeave}
            touchSensitivity={20}
            render={() => (
                <ReactFullpage.Wrapper>
                    {fullPageData.map(content => (
                        <React.Fragment key={content.Id}>
                            <GlobalComponent
                                displayData={content}
                                reforgedMenu={menuContentObj}
                                activeSection={activeSection}
                                sectionThemeObj={screenTheme}
                                copyLink={copyLink}
                                blogContent={applicationDetails?.applicationScreen?.blogContent}
                                isGameFeatureModalOpen={isGameFeatureModalOpen}
                                setIsGameFeatureModalOpen={setIsGameFeatureModalOpen}
                                isGalleryModalOpen={isGalleryModalOpen}
                                setIsGalleryModalOpen={setIsGalleryModalOpen}
                                isExploreeModalOpen={isExploreeModalOpen}
                                setIsExploreeModalOpen={setIsExploreeModalOpen}
                                isLongWebAddress={isLongWebAddress}
                            />
                        </React.Fragment>
                    ))}
                </ReactFullpage.Wrapper>
            )}
        />
    );

    const renderOtherComponent = () => (
        zones
            .filter(zone => zone?.ScreenDTO?.ScreenTypeId !== 2)
            .map(zone => (
                <GlobalComponent
                    displayData={zone}
                    key={zone.Id}
                    surveyFormData={applicationDetails?.applicationScreen?.surveyFormData}
                    shopifyProductDetails={shopifyProductDetails}
                    reforgedMenu={menuContentObj}
                    menuData={reforgedHeader}
                    isLongWebAddress={isLongWebAddress}
                />
            ))
    );

    return (
        <Auth0Provider
            domain={process.env.GATSBY_AUTH0_DOMAIN}
            clientId={process.env.GATSBY_AUTH0_CLIENTID}
            audience={process.env.GATSBY_AUTH0_AUDIENCE}
            redirectUri={process.env.GATSBY_AUTH0_LOGIN_REDIRECT}
            onRedirectCallback={onRedirectCallback}
        >
            <MenuContextProvider>
                <SearchContextProvider>
                    <Layout PageTitle={screenName} webLogo={webLogo} webURL={location?.origin} sectionThemeObj={screenTheme}>
                        {!isLongWebAddress && menuData?.length ? (
                            <HeaderOne
                                menuData={menuData}
                                headerDetail={commonDetail}
                                screens={screens}
                            />
                        ) : ""}
                        {isReforged &&
                            <div id='fullpage-container'>
                                <div className="social-links-wrap" onClick={() => setIsOpen(!isOpen)}>
                                    <div className="byte-dropdown">
                                        <img src={menuContentObj?.shareIcon?.url} className="share" />
                                        <ul className="byte-dropdown-menu share-dropdown" x-placement="bottom-end" style={{ ...(!isOpen && { display: "none" }), backgroundImage: `url(${menuContentObj?.shareLinksBackground?.url})` }}>
                                            {menuContentObj?.images.map((menu, i) => {
                                                if (menu?.name?.toLowerCase().split(' ').join('') === 'copylink' || menu?.href?.toLowerCase().split(' ').join('') === 'copylink') {
                                                    return <li key={`menu_${i}`} className="byte-dropdown-item mb-1" onClick={copyLink}>
                                                        <span className="byte-dropdown-item__text">
                                                            <div className="d-flex justify-content-start align-items-baseline">
                                                                <div>
                                                                    <img src={menu?.url} alt="" className="copy-link" />
                                                                </div>
                                                                <div className="link-name" style={{ color: screenTheme ? screenTheme?.linkFont : "" }}>{menu?.name}</div>
                                                            </div>
                                                        </span>
                                                    </li>
                                                } else {
                                                    return <li key={`menu_${i}`} className="byte-dropdown-item mb-1">
                                                        <span className="byte-dropdown-item__text">
                                                            <a href={menu?.href} target="_blank">
                                                                <div className="d-flex justify-content-start align-items-baseline">
                                                                    <div>
                                                                        <img src={menu?.url} alt="" className="tw-link" />
                                                                    </div>
                                                                    <div className="link-name" style={{ color: screenTheme ? screenTheme?.linkFont : "" }}>{menu?.name}</div>
                                                                </div>
                                                            </a>
                                                        </span>
                                                    </li>
                                                }
                                            })}
                                        </ul>
                                    </div>
                                </div>
                                {<HeaderOne
                                    isZweihanderWeb={isLongWebAddress}
                                    menuData={reforgedHeader}
                                    sectionThemeObj={screenTheme}
                                    headerDetail={commonDetail}
                                    fullPage
                                    activeSection={activeSection}
                                    setActiveSection={setActiveSection}
                                />}
                                {fullPageData.length && anchor.length && renderFullpage()}
                                <div className="fire-container">
                                    <span href="#features" onClick={() => {
                                        if (activeHomeSection) window.fullpage_api.moveTo('home', 0)
                                        else window.fullpage_api.moveSectionDown()
                                    }} className="fire-box fire_features" style={{ opacity: 1, transition: "all 0.3s ease 0s" }}>
                                        <img src={activeHomeSection ? menuContentObj?.moveUpArrow?.url : menuContentObj?.moveDownArrow?.url} alt="fire" className={`${activeHomeSection ? "up-arrow" : "keyFrames-fire"}`} />
                                    </span>
                                </div>
                            </div>
                        }
                        {renderOtherComponent()}
                        {!isReforged && footerDetail?.length ? <Footer footerDetail={footerDetail} /> : ""}
                        <ToastContainer
                            position="top-center"
                            autoClose={3000}
                            closeButton={false}
                            hideProgressBar
                            pauseOnHover={false}
                            theme="dark"
                        />
                    </Layout>
                </SearchContextProvider>
            </MenuContextProvider>
        </Auth0Provider>
    );
};

export default GlobalPage;
