import React, { useContext, Fragment, useRef } from 'react'
import { MenuContext } from "@/context/menu-context";
import { useSelector } from "react-redux";

const ZweihanderPopup = ({ sectionThemeObj }) => {
  const menuData = useSelector(state => state.wogdAppData.reforgedMenus);
  const { reforgedMenuStatus, updateReforgedMenuStatus } = useContext(MenuContext);
  const menuEl = useRef(null);

  const handleMenuClick = e => {
    e.preventDefault();
    updateReforgedMenuStatus(!reforgedMenuStatus);
  };

  const onRedirect = (anchor, index) => {
    window.fullpage_api.moveTo(anchor.toLowerCase(), index)
    updateReforgedMenuStatus(false)
  }

  return (
    <div className="zweihander-popup show-overlay-nav">
      <div className="popup popup__menu">
        <div className="w-100">
          <div className="row">
            <div className="col-lg-12 p-0">
              <div className="popup-inner">
                <nav
                  className="zweihander-popup-menu dl-menu__wrap text-center"
                  ref={menuEl}
                >
                  <div className="menu-list-block">
                    <div className="menu-list-content">
                      {menuData.map((item, i) => (
                        <Fragment key={`menu_item_${i}`}>
                          <div className="menu-item-block">
                            <img src="https://pub-5e7e95bd13024dac8a8a03a41e41af9c.r2.dev/cms/MenuDecor1/20231230063235645_987.png" alt="" />
                            <div className="title" onClick={() => onRedirect(item.name, i)} style={{ color: sectionThemeObj ? sectionThemeObj?.menuFont : "" }}>{item?.name.toUpperCase()}</div>
                          </div>
                          {menuData.length - 1 !== i && !item?.subMenu.length &&
                            <div className="menu-item-decor">
                              <img src="https://pub-5e7e95bd13024dac8a8a03a41e41af9c.r2.dev/cms/MenuDecor2/20231230063256193_988.png" alt="" />
                            </div>
                          }
                          {item?.subMenu.length ?
                            <div className="cell-community">
                              <div className="cell-community-children">
                                {item?.subMenu.map((subLinks, i) => (
                                  <div key={`sub_menu_${i}`} className="community-children-block">
                                    <img src="https://pub-5e7e95bd13024dac8a8a03a41e41af9c.r2.dev/cms/SubmenuIdentifier/20240101071827380_991.png" alt="" />
                                    <a className="title" href={subLinks?.href} target='_blank' style={{ color: sectionThemeObj ? sectionThemeObj?.subMenuFont : "" }}>{subLinks?.name.toUpperCase()}</a>
                                  </div>
                                ))
                                }
                              </div>
                            </div> : ""
                          }
                        </Fragment>)
                      )
                      }
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ZweihanderPopup