import React from 'react'


const Input = ({
    handleChange,
    inputType,
    questionDescription,
    questionName,
    inputIcon,
    label,
    isFocused,
    setIsFocused,
    handleBlur,
    surveyName,
    errors,
    touched,
}) => {
    return (
        <div className="input-group" >
            {
                !label && <span className="input-group-addon"><i className={`ti-${inputIcon} text-primary`}></i></span>
            }            <label htmlFor="exampleInputEmail1" className="form-label">{label && questionName}</label>
            <input
                id='exampleInputEmail1'
                className={surveyName && touched[surveyName] &&
                    errors[surveyName] && 'input-outline'}
                name={questionDescription}
                type={inputType}
                placeholder={!label && questionName}
                onBlur={handleBlur}
                onMouseEnter={() => setIsFocused(!isFocused)}
                onChange={handleChange}
            />
        </div>
    )
}

export default Input